<template>
  <router-view></router-view>
</template>
<script>
export default {
  created() {
    fetch("https://ipinfo.io/json")
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log(data);
          console.log(data.country);
          // callback(data.country); // 调用回调函数，传入国家信息
        })
        .catch(function (error) {
          console.error("Error fetching IP info: ", error);
        });
  },
  methods: {

  }
};
</script>

<style lang="less">
.app {
  padding-top: 80px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--font-icon-t3);
  background: transparent;
  background-color: #353b45;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: var(--font-icon-t3);
}

::-webkit-scrollbar-track {
  background: var(--el-color-white);
}
</style>
