export default {
    common: {
        more: "More",
        pay_success: "Payment successful!",
        pay_loading: "Payment in progress...",
    },
    header: {
        donate: "Donate",
        nav1: "HomePage",
        nav2: "Donation",
        nav3: "Education",
        nav4: "Animal Protection",
        nav5: "About Us",
    },
    footer: {
        contact: "contact",
    },
    about: {
        h4: "New Hope World Alliance Foundation",
        about_p1: "New Hope World Alliance Foundation is an international non-profit organization that upholds the concept of being warm charity. We actively perform social responsibility to provide services and support to the global community, including education, medical care, social welfare, animal protection, intangible cultural heritage protection and other fields.",
        about_p2: "New Hope World Alliance Foundation aims to promote the high-quality and vigorous development of global community education and environment, is committed to helping underdeveloped areas to gain fair and balanced education resources, protecting and caring for the growth and development of children. At the same time, it is committed to ensuring that animals are treated fairly in society and creating a better living environment for animals.",
    },
    involved: {
        p1: "Your support can save lives",
        p2: " Your support means that we give thousands of dogs and cats a second chance each year. Bring happiness to more animals and the people who care for them.",
        p3: "Thank you for cooperating with us. Please contact us at contact",
        p4: "The most recent donation records",
        p5: "made a donation.",
        p6: "Secure donation",
        p7: "One-time",
        p8: "One-month",
        p9: "Feed for a day",
        amount_place: "Please enter Amount",
        place_select: "Please enter Amount",
        donate_now: "Donate now",
    },
    donation: {
        h4: "New Hope World Alliance Foundation",
        donation_tit1: "Where will your donation go?",
        donation_tit2: "How to Donate without a fee?",
        donation_tit3: "What organization is the New Hope World Alliance Foundation?",
        donation_p1: "All of your donation will be used in the program to improve disadvantaged children’s education and help stray animals thrive, and all of our work around the world will be completed by volunteers.",
        donation_p2: "When you donate via PayPal or credit card, New Hope World Alliance Foundation will pay the 2.2% fee.",
        donation_p3: "Donations can be made online or by check. New Hope World Alliance Foundation is a charitable organization established under Section 501(c)3 of the Internal Revenue Code, and our tax code number is 99-0623522. Donors will receive a receipt for their tax-deductible donation and we sincerely appreciate your support.",
        donation_click1: "Improving the education and lives of ",
        donation_click2: "Help",
        donation_click3: "usher in a warm tomorrow",
        donation_span1: "disadvantaged children",
        donation_span2: "stray animals",
        donation_btn: "Click the button to donate",
    },
    education: {
        h4: "Help them",
        education_p1: "New Hope World Alliance Foundation has a long-term project for dedicated public welfare fund, which uses the Internet as a transmission link and relies on the 'Hope Classroom' project, to provide education, health, school supplies and clothing for poor children. We hope that this kind of action will drive more public attention, consensus and participation in the education of disadvantaged children, and pass on love and positive energy together.",
    },
    home: {
        notice: "New Hope World Alliance Foundation—Be warm charity.",
        banner1: "Make it a better world with education.",
        banner2: "Light up the life for animals with love.",
        donate_now: "Donate Now",
        home_box_tit1: "Education",
        home_box_tit2: "Animal Protection",
        home_box_p1: "New Hope World Alliance Foundation is committed to providing high-quality education services to disadvantaged children.",
        home_box_p2: "New Hope World Alliance Foundation is committed to providing services to stray animals",
        home_box3_tit: "New Hope World Alliance Foundation",
        home_box3_p: "New Hope World Alliance Foundation has a long-term project for dedicated public welfare fund, which uses the Internet as a transmission link and relies on the 'Hope Classroom' project, to provide education, health, school supplies and clothing for poor children. We hope that this kind of action will drive more public attention, consensus and participation in the education of disadvantaged children, and pass on love and positive energy together.",
    },
    protection: {
        h4: "Help them",
        protection_p1: "New Hope World Alliance Foundation is dedicated to protecting and caring for animals in need, reducing the number of stray animals on the streets through neutering, as well as rescue, vaccination, medical treatment and shelter. We are characterized by a deep understanding and respect for every life and strive to create a more tolerant and caring society. Each of your support will be transformed into the power of our actions to help more helpless little lives find their home. Join us to create a world of love.",
    },


};  