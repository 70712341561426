import request from "@/utils/request";

export function login(data) {
    return request({
        url: "/api/v1/auth/signin",
        method: "post",
        data,
    });
}

export function register(data) {
    return request({
        url: "/api/v1/auth/signup",
        method: "post",
        data,
    });
}

export function captcha() {
    return request({
        url: "/api/v1/auth/captcha",
        method: "get",
    });
}


export function forget(data) {
    return request({
        url: "/api/v1/auth/forget-pass",
        method: "post",
        data,
    });
}

export function forgetPay(data) {
    return request({
        url: "/api/v1/auth/forget-pay-pass",
        method: "post",
        data,
    });
}

export function registerCode(data) {
    return request({
        url: "/api/v1/auth/signup-captcha",
        method: "post",
        data,
    });
}

export function forgotCode(data) {
    return request({
        url: "/api/v1/auth/forget-captcha",
        method: "post",
        data,
    });
}

export function getInfo() {
    return request({
        url: "/api/v1/profile/profile",
        method: "get",
    });
}

export function logout() {
    return request({
        url: "/api/v1/auth/logout",
        method: "post",
    });
}
