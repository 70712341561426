import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/donation',
    name: 'donation',
    component: () => import('@/views/home/donation'),
  },
  {
    path: '/education',
    name: 'education',
    component: () => import('@/views/home/education'),
  },
  {
    path: '/protection',
    name: 'protection',
    component: () => import('@/views/home/protection'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/home/about'),
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('@/views/home/article'),
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('@/views/home/donate'),
  },
  {
    path: '/pay-state',
    name: 'donate',
    component: () => import('@/views/home/pay-state'),
  },
]

const router = new VueRouter({
  routes
})
router.afterEach(()=> {
  window.scrollTo(0,0)
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
