export default {
    common: {
        more: "更多",
        pay_success: "支付成功！",
        pay_loading: "正在支付中...",
    },
    header: {
        donate: "捐贈",
        nav1: "家",
        nav2: "使命",
        nav3: "故事",
        nav4: "參與進來",
        nav5: "關於我們",
    },
    footer: {
        friends_link: "友誼連結",
        bai_du: "百度",
        sina: "新浪",
        net_ease: "網易",
        nav_tit1: "企業簡介",
        nav_tit2: "服務範圍",
        nav1: "品牌概述",
        nav2: "團隊簡介",
        nav3: "關於我們",
        nav4: "產品展示",
        nav5: "新聞和更新",
        nav6: "服務品牌",
        donate: "立即捐款",
    },
    home: {
        box1_p1: "有影響",
        box1_p2: "對於動物",
        box1_p3: "誰最需要你",
        box1_p4: "我們可以一起幫助動物茁壯成長。",
        box1_p5: "現在捐贈",
        box2_p1: "與我們一起，您將致力於結束所有動物的痛苦。",
        box2_p2: "給寵物第二次機會和幸福的家園。",
        box4_p1: "我們推動為動物帶來真正、切實的改變。",
        box4_p2: "奶油的拯救故事",
        box4_p3: "在一個陽光明媚的日子裏，我們的團隊接到一個電話，說有一隻小流浪狗在獨自徘徊。 到達目的地後，我們見到了Cream--一隻受驚的小狗，眼睛裏充滿了茫然。 Cream從街頭走向安全的旅程始於一張溫暖的床和一頓營養餐。 我們的獸醫團隊為它提供了……",
        box5_p1: "把它們都救出來",
        box5_p2: "我們的使命是通過資助動物收容所和建立長期合作夥伴關係，將服務直接帶入社區，從而提高動物和家庭的福祉，减少服務不足地區的動物所遭受的苦難。",
        box6_p1: "當動物們最需要我們的時候，我們就會出現。",
    },
    involved: {
        p1: "您的支持可以拯救生命",
        p2: "您的支持意味著我們每年為成千上萬的猫狗提供第二次機會。 為更多的動物和照顧它們的人們帶來快樂。",
        p3: "如有任何問題，請通過電子郵件與我們聯系，電子郵寄地址為",
        p4: "最新捐贈記錄",
        p5: "捐款",
        p6: "安全捐贈",
        p7: "一次",
        p8: "一個月",
        p9: "喂一天",
        amount_place: "請輸入金額",
        place_select: "請輸入金額",
        donate_now: "立即捐款",
    },
    mission: {
        p1: "當動物最需要我們的時候，我們就在那裡",
        p2: "我們的使命是通過資助動物收容所和建立長期合作夥伴關係，將服務直接帶入社區，從而提高動物和家庭的福祉，從而减少服務不足地區動物所遭受的苦難。",
        p3: "我們相信，通過教育和社區參與，解决虐待動物的根本原因，而不僅僅是對個別動物的援助。",
        p4: "我們與街頭動物的合作包括絕育和絕育，以防止不想要的小狗和小猫出生在痛苦的短暫生命中，以及救援、疫苗接種、醫療和庇護。",
        p5: "我們正在進行的使命依賴於捐款和籌款的支持。 每一筆捐款都100%用於動物的救援、護理和醫療需求。",
    },
    stories: {
        p1: "救援故事展示！",
        p2: "認識Coraline",
        p3: "Coraline來自當地一家收容所，雙目失明，孤身一人。 這只穿燕尾服的小猫只有1磅重。 除了Coraline的眼睛問題",
    }
};  