import Vue from 'vue';
import VueI18n from 'vue-i18n';
import elementEN from 'element-ui/lib/locale/lang/en'
import elementZH_TW from 'element-ui/lib/locale/lang/zh-TW'
Vue.use(VueI18n);

//导入语言包
import EN from '../lang/en.js'; // 英文语言包
import ZH_TW from '../lang/zh-TW.js'; // 英文语言包


const messages = {
    en: {
        ...elementEN,
        ...EN
    },
    zh_tw: {
        ...elementZH_TW,
        ...ZH_TW
    },
}
window.localStorage.setItem("lang", 'zh_tw')
    // window.sessionStorage.setItem("lang", 'zh')
export default new VueI18n({
    locale: 'en', // set locale 默认显示英文
    fallbackLocale: 'zh_tw', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});